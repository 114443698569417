<template>
    <!-- This is an example component -->
    <div class="h-screen">
        <div class="max-w-3xl mx-auto px-4 pb-20 pt-8">
            <div v-if="step === 'complete'">
                <StepComplete :back_to_home="back_to_home" />
            </div>

            <div v-if="step != 'complete' && step != 0">
                <!-- Top Navigation -->
                <Header :step="step" />
                <!-- /Top Navigation -->

                <!-- Step Content -->
                <div class="py-10">
                    <div v-if="step === 1">
                        <div class="mb-4">
                            <label for="dealer_id" class="font-bold mb-1 text-black block">รับสินค้าจาก Dealer / ตัวแทนจำหน่าย <span class="text-red-500">*</span></label>
                            <Multiselect 
                                v-model="form.dealer_id"
                                :options="dealer_options"
                                searchable
                                @select="select_dealer"
                                @clear="clear_dealer"
                                placeholder="Select option"
                            />
                        </div>
                        
                        <div class="mb-4">
                            <label for="product" class="font-bold mb-1 text-black block">Barcode / Serial Number / Product Name <span class="text-red-500">*</span></label>
                            <AutoComplete
                                :list="products"
                                :value="form.product"
                                @chosen="handle_chosen"
                                @input:field="input_product"
                                @enter="check_product"
                                :loading="loading"
                                inputClass="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600 border border-gray-300"
                                placeholder="Scan Barcode / Serial Number / Product Name..."
                                :open_scanner="() => open_scanner('Scan Barcode / Serial Number / Product Name', 'product')"
                            />
                        </div>

                        <div class="mb-4">
                            <label for="serial" class="font-bold mb-1 text-black block">Serial Number</label>
                            <div class="relative">
                                <input 
                                    v-model="form.serial"
                                    @keypress.enter="check_serial_with_barcode"
                                    ref="serial"
                                    type="text" 
                                    name="serial" 
                                    class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600 border border-gray-300" 
                                    placeholder="Scan Serial Number..." 
                                    autocomplete="off"
                                />
                                <span class="absolute inset-y-0 right-0 flex items-center">
                                    <button @click="open_scanner('Scan Serial Number', 'serial')" class="px-2 py-1 mr-2 focus:outline-none focus:shadow-outline border border-gray-200 hover:bg-gray-200 rounded-2xl">
                                        <em class="fas fa-barcode"></em>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <div class="mb-4 grid grid-cols-6">
                            <div class="col-start-2 col-span-4">
                                <span class="font-bold">Barcode : </span> <span>{{ form.barcode }}</span>
                            </div>
                            <div class="col-start-2 col-span-4">
                                <span class="font-bold">Model Name : </span> <span>{{ form.model_name }}</span>
                            </div>
                            <div class="col-start-2 col-span-4">
                                <span class="font-bold">Serial Number : </span> <span>{{ form.serial_show }}</span>
                            </div>
                        </div>

                        <div class="mb-4">
                            <label for="service_code" class="font-bold mb-1 text-black block">ถุง <span class="text-red-500">*</span></label>
                            <div class="relative">
                                <input 
                                    v-model="form.service_code"
                                    @keypress.enter="check_service_code"
                                    ref="service_code"
                                    type="text" 
                                    name="service_code" 
                                    class="w-full px-4 py-3 rounded-lg shadow-sm focus:shadow-outline text-gray-600 border border-gray-300" 
                                    placeholder="Scan ถุง..." 
                                    autocomplete="off"
                                />
                                <span class="absolute inset-y-0 right-0 flex items-center">
                                    <button @click="open_scanner('Scan ถุง', 'service_code')" class="px-2 py-1 mr-2 focus:outline-none focus:shadow-outline border border-gray-200 hover:bg-gray-200 rounded-2xl">
                                        <em class="fas fa-barcode"></em>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="step === 2">
                        <StepValidation :form="form" :dealer_options="dealer_options" />
                    </div>
                </div>
                <!-- / Step Content -->
            </div>
        </div>
        <div v-if="open" class="block-sanncer">
            <Scanner :open="open" :on_close="close_scanner" :data_scanner="data_scanner" @scan_decode="scan_decode" />
        </div>
        <!-- Bottom Navigation -->
        <div v-if="step != 'complete' && step != 0 && open==false" class="fixed bottom-0 left-0 right-0 py-4 bg-gray-200 shadow-md z-10">
            <Footer :step="step" :reset_data="reset_data" :next_step="next_step" :previous_step="previous_step" :complete_step="complete_step" />
        </div>
    </div>
</template>

<script>
    import api from '@/services/auth'
    import Multiselect from '@vueform/multiselect'
    import Header from '@/components/Header'
    import Scanner from '@/components/Scanner'
    import AutoComplete from '@/components/AutoComplete'
    import StepValidation from '@/components/StepValidation'
    import Footer from '@/components/Footer'
    import StepComplete from '@/components/StepComplete'
    import Swal from 'sweetalert2'

    export default {
        name: "App",
        components: {
            Multiselect,
            Header,
            AutoComplete,
            Scanner,
            StepValidation,
            StepComplete,
            Footer,
        },
        data() {
            let step = 1;
            let form = {
                dealer_id:'',
                product:'',
                model_id:'',
                barcode:'',
                model_name:'',
                serial:'',
                serial_show:'',
                service_code:'',
            };
            let dealer_options = [];
            let products = [];
            let loading = false;
            let open = false;
            let data_scanner = {};
            return {
                step,
                form,
                dealer_options,
                products,
                loading,
                open,
                data_scanner
            }
        },
        methods: {
            onDecode (result) { console.log(result) },
            select_dealer(){
                console.log("select dealer");
            },
            clear_dealer(){
                this.form.dealer_id = "";
            },
            close_scanner(){
                this.open = false;
            },
            get_product(){
                if(this.form.product.length >= 3){
                    this.loading = true;
                    let params = {
                        params:{
                            keyword:btoa(this.form.product),
                            page_type:1
                        }
                    }
                    api.get(`/api/messenger_products`, params)
                        .then(response => {
                            this.products = response.data.data;
                            this.loading = false;
                        }).catch( error => {
                            console.log(error);
                            this.loading = false;
                        })
                }else{
                    this.products = [];
                }
            },
            input_product(event){
                this.form.product = event;
                this.get_product();
            },
            handle_chosen(event){
                this.form.product = event.model_name
                this.check_product()
            },
            check_product(){
                if(this.form.product != ""){
                    api.get(`/api/messenger_products/${btoa(this.form.product)}`)
                        .then(response => {
                            if(response.data.status==0){
                                this.form.model_id = response.data.data.model_id
                                this.form.model_name = response.data.data.model_name
                                this.form.barcode = response.data.data.barcode
                                this.form.serial = response.data.data.serial
                                this.form.serial_show = response.data.data.serial
                                if(response.data.data.serial==""){
                                    this.$refs.serial.focus();
                                }else{
                                    this.$refs.service_code.focus();
                                }
                            }else{
                                Swal.fire({
                                    icon: 'error',
                                    text: response.data.message,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText:'OK',
                                });
                                this.form.product = ""
                                this.form.model_id = ""
                                this.form.model_name = ""
                                this.form.barcode = ""
                                this.form.serial = ""
                                this.form.serial_show = ""
                            }
                        }).catch( error => {
                            console.log(error);
                        })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: "Please Scan Barcode / Serial Number / Product Name",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText:'OK',
                    });
                }
            },
            open_scanner(title, label){
                this.open = true;
                this.data_scanner.title = title;
                this.data_scanner.label = label;
            },
            scan_decode(response){
                let {label, result} = response;
                console.log(label, result);
                if(label == "product"){
                    this.form.product = result
                    this.check_product()
                }else if(label == "serial"){
                    this.form.serial = result
                    this.check_serial_with_barcode()
                }else if(label == "service_code"){
                    this.form.service_code = result
                    this.check_service_code()
                }
            },
            check_serial_with_barcode(){
                if(this.form.serial != "" && this.form.barcode != ""){
                    api.get(`/api/serials/${btoa(this.form.serial)}/barcode/${btoa(this.form.barcode)}`)
                        .then(response => {
                            if(response.data.status==0){
                                this.form.model_id = response.data.data.model_id
                                this.form.model_name = response.data.data.model_name
                                this.form.barcode = response.data.data.barcode
                                this.form.serial = response.data.data.serial
                                this.form.serial_show = response.data.data.serial
                                
                                this.$refs.service_code.focus();
                            }else{
                                Swal.fire({
                                    icon: 'error',
                                    text: response.data.message,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText:'OK',
                                });
                                this.form.serial = ""
                                this.form.serial_show = ""
                            }
                        }).catch( error => {
                            console.log(error);
                        })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: "Please Scan Barcode/Serial Number",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText:'OK',
                    });
                }
            },
            check_service_code(){
                if(this.form.service_code != ""){
                    api
                    .get(`/api/after_service/${this.form.service_code}/duplicate`)
                    .then(response => {
                        if (response.data.status!=0) {
                            Swal.fire({
                                icon: 'error',
                                text: response.data.message,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText:'OK',
                            });
                        }
                    }).catch( error => {
                        console.log(error);
                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: "Please Scan ถุง",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText:'OK',
                    });
                }
            },
            reset_data(){
                this.form = {
                    dealer_id:'',
                    product:'',
                    model_id:'',
                    barcode:'',
                    model_name:'',
                    serial:'',
                    serial_show:'',
                    service_code:'',
                };
            },
            previous_step(){
                this.step = 1;
            },
            next_step(){
                let array_input = [
                    {input:'dealer_id', label:'Select Dealer / ตัวแทนจำหน่าย'},
                    {input:'product', label:'Scan Barcode / Serial Number / Product Name'},
                    {input:'model_id', label:'Scans Barcode / Serial Number / Product Name ให้ถูกต้อง'},
                    {input:'service_code', label:'Scan ถุง'},
                ];
                if(array_input.findIndex(item => this.form[item.input]=="") >= 0){
                    Swal.fire({
                        icon: 'error',
                        text: `Please ${array_input.find( item => this.form[item.input]=="").label}`,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText:'OK',
                    });
                    return false
                }
                this.step = 2;
            },
            complete_step(){
                api
                .post(`/api/messenger_after_service`, this.form)
                .then(response => {
                    if (response.data.status==0) {
                        this.step = 'complete';
                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText:'OK',
                        });
                    }
                }).catch( error => {
                    let error_message = Object.keys(error.response.data.errors).map(item => {
                        return error.response.data.errors[item][0]
                    }).join("<br>")
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        html: error_message,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText:'OK',
                    });
                })
                
            },
            back_to_home(){
                this.reset_data();
                this.previous_step();
            }
        },
        mounted () {
            api.get(`/api/dealers`, {params : {page_type:1}})
                .then(response => {
                    this.dealer_options = response.data.data.map(item => {
                        return {
                            value : item.id,
                            label : item.name
                        }
                    })
                }).catch( error => {
                    console.log(error);
                })
        }
    };
</script>

<style>
.multiselect-caret{
    z-index: 0 !important;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
